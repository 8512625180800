html {
  overflow-y: scroll;
}

.main#wrap {
  align-items: center;
  padding-bottom: 150px; /*whatever the height of your footer is*/
}

.backgroundImage {
  position: relative;
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  z-index: -9;
  justify-content: center;
}

.backgroundImage::before {
  content: "";
  background-image: linear-gradient(
      rgba(11, 41, 152, 0.45),
      rgba(26, 38, 84, 0.45)
    ),
    url(../../assets/backgroundNasser-short.png);
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.95;
}

.backgroundImageText {
  position: relative;
  color: #ffffff;
  font-size: 25px;
  font-weight: 500;
  text-align: center;
}

.backgroundImageText2 {
  position: relative;
  color: #ffffff;
  font-size: 60px;
  font-weight: 700;
  text-align: center;
}

.anzeige {
  position: relative;
  align-items: center;
  text-align: center;
}

.anzeige img {
  width: 150px;
}

.anzeige p {
  color: blue;
  font-size: 18px;
  font-weight: 600;
}

.termin {
  position: relative;
  align-items: center;
  text-align: center;
}

.nasser {
  position: relative;
  align-items: center;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #ebeff5;
  z-index: -1;
}

.form {
  padding-left: 100px;
  padding-right: 50px;
  padding-top: 30px;
  padding-bottom: 50px;
  align-items: center;
  text-align: left;
}

.map {
  padding-top: 50px;
  position: relative;
  align-items: center;
  text-align: center;
}

.praxis {
  padding-top: 150px;
  align-items: center;
  text-align: center;
  padding-bottom: 200px;
}

.leistung {
  padding-top: 100px;
  align-items: center;
  text-align: center;
  padding-bottom: 150px;
  margin: auto;
  width: 50%;
}

.anfahrt {
  padding-top: 100px;
  align-items: center;
  text-align: center;
  padding-bottom: 150px;
}

.kontakt {
  padding-top: 100px;
  align-items: center;
  text-align: center;
  padding-bottom: 200px;
}

.leistungen {
  position: relative;
  padding-top: 100px;
  background-color: #00387b;
  padding-bottom: 150px;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  align-items: center;
  text-align: center;
}

.leistungenDiv {
  width: 50%;
  margin: 0 auto;
  padding-top: 100px;
  padding-bottom: 150px;
  position: relative;
  text-align: center;
}

.leistungen h1 {
  color: white;
  font-weight: bold;
}

.leistungenDiv hr {
  border: 1px solid white;
  margin: auto;
}

.leistungenDiv button {
  border: none;
  width: 98%;
  color: white;
  font-weight: bold;
  background-color: inherit;
  font-weight: bold;
  text-align: left;
}

.leistungenDiv h3 {
  color: white;
  font-weight: bold;
  text-align: left;
}

.leistungenDiv p {
  color: white;
  padding: 30px;
  position: relative;
  margin: auto;
  text-align: left;
}

.datenschutz {
  position: relative;
  padding-top: 100px;
  align-items: center;
  text-align: center;
  padding-bottom: 200px;
}

.arrow {
  border: solid rgb(255, 255, 255);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  z-index: 99;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  bottom: 0;
  border-radius: 20px;
  align-items: center;
  text-align: center;
  padding: 20px;
  float: right;
  width: 250px;
  background-color: #0596de;
  z-index: 999;
}

.successText {
  color: rgb(53, 164, 127);
}

.sticky img {
  height: 30px;
  width: 100px;
}

.sticky span {
  color: white;
  padding: 10px;
}
